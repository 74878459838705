import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import JobPosition from "../api/JobPosition";
import { emitCustomEvent } from "react-custom-events";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Input from "../components/form/Input";
import FormSelect from "../components/atoms/FormSelect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Certification from "../api/Certification";
import ReactTags from "react-tag-autocomplete";
import DraftApi from "../api/Draft";
import PredefinedJob from "../api/PredefinedJob";
import dayjs from "dayjs";
import Draft from "../components/Draft";
import { DashboardLayout } from "../components/organisms/Layout";
import { addIcon } from "../assets/images";
import { decodeIds } from "../utils";
import {
  darkmode,
  selectSelectedLocation,
  selectUser,
} from "../store/authSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./JobPosition.scss";
import TextInputWithDropdown from "../components/form/TextInputWithDropdown";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { Tooltip } from "react-tooltip";

const hiringStatusList = [
  { value: 0, label: "Accepting Applications" },
  { value: 1, label: "Immediately Hiring" },
];

const availabilityList = [
  { value: "full-time", label: "Full Time" },
  { value: "part-time", label: "Part Time" },
];

const instagramList = [
  { value: 1, label: "Yes - You will be able to view Job Seekers Feed" },
  {
    value: 0,
    label: "No - Job Seeker can apply without linking their insta account",
  },
];

const AddJobPosition = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);
  const mode = useSelector(darkmode);
  const [title, setTitle] = useState("");
  const [wage, setWage] = useState("");
  // const [instagram, setInstagram] = useState(0);
  const [startDate, setStartDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [startDateType, setStartDateType] = useState("date");
  const [hiringStatus, setHiringStatus] = useState("");
  const [position, setPosition] = useState("");
  const [description, setDescription] = useState("");
  const [experience, setExperience] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const editMode = location.pathname.search("/job-positions/edit/") !== -1;
  const draftMode = location.pathname.search("draft/edit") !== -1;
  const [decodedId, setDecodedId] = useState(
    decodeIds(params?.jobId || params?.draftId)[0]
  );
  const [minDate, setMinDate] = useState(new Date());
  const [prejobs, setPreJobs] = useState([]);
  const [selectJobPosition, setSelectJobPosition] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [uploadResume, setUploadResume] = useState(false);
  const [requireInstagram, setRequireInstagram] = useState(false);
  const [showQuestionInput, setShowQuestionInput] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [questionID, setQuestionID] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);

  const handleEditQuestion = (question) => {
    setEditingQuestionId(question.id);
  };

  const handleUpdateQuestion = (updatedQuestion) => {
    if (updatedQuestion) {
      const updatedQuestions = questions.map((q) =>
        q.id === editingQuestionId
          ? {
              ...q,
              title: updatedQuestion.title,
              description: updatedQuestion.description,
              requirement: updatedQuestion.requirement,
            }
          : q
      );
      setQuestions(updatedQuestions);
    }
    setEditingQuestionId(null);
    setOpenDropdownId(null);
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const deleteQuestion = async (id) => {
    setQuestionID(id);
    setOpenDeleteConfirmationModal(true);
  };

  const handleRemoveQuestion = async () => {
    try {
      const updatedQuestions = questions
        ?.filter((question) => question.id !== questionID)
        .map((question, index) => ({
          ...question,
          title: `Question ${index + 1}`,
        }));
      setQuestions(updatedQuestions);
      setOpenDeleteConfirmationModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const addQuestion = () => {
    setShowQuestionInput(true);
  };

  const handleCreateQuestion = (newQuestion) => {
    if (newQuestion) {
      const questionWithId = {
        id: questions.length + 1,
        title: `Question ${questions.length + 1}`,
        description: newQuestion.description,
        requirement: newQuestion.requirement,
      };

      setQuestions([...questions, questionWithId]);
    }
    setShowQuestionInput(false);
  };

  const addJobPosition = () => {
    setLoadingButton(true);

    const requestBody = {
      title: title,
      availability: position,
      description: description,
      experience: experience,
      hiring_status: hiringStatus,
      instagram_required: requireInstagram ? 1 : 0,
      wage: wage,
      resume_required: uploadResume ? 1 : 0,
      certifications: selectedCertifications
        .map((certification) => certification.id)
        .toString(),
      questions: JSON.stringify(
        questions?.map((question) => ({
          description: question.description,
          requirement: question.requirement,
        }))
      ),
    };
    const request = editMode
      ? JobPosition.update(decodedId, requestBody)
      : JobPosition.add(requestBody);

    request
      .then(() => {
        emitCustomEvent("closeModal");
        emitCustomEvent("dataChanged");
        toast.success(
          t(
            editMode
              ? "job-positions.job-position-updated"
              : "job-positions.job-position-added"
          )
        );
        if (draftMode) {
          DraftApi.deleteDraft(decodedId);
        }
        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else if (error.response && error.response.status === 403) {
          toast.error(t("common.access-denied"));
          navigate("/subscription");
        } else {
          toast.error(t("common.something-went-wrong-please-try-again"));
        }
      })
      .finally(() => setLoadingButton(false));
  };

  const loadPredefinedJobs = async () => {
    PredefinedJob.getByCategory(
      selectedLocation?.category_id || user?.location?.category_id
    )
      .then((response) => {
        setPreJobs(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Failed to load predefined jobs. Please try again.");
        }
      });
  };

  const loadCertifications = () => {
    setLoading(true);

    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch()
      .finally(() => setLoading(false));
  };

  const addCertification = (certification) => {
    Certification.add({
      category_id: selectedLocation?.category_id || user?.location?.category_id,
      name: certification.name,
    }).then((response) => {
      setSelectedCertifications([
        ...selectedCertifications,
        response.data.data,
      ]);
    });
  };

  const removeCertification = (index) => {
    const copy = selectedCertifications.slice(0);
    copy.splice(index, 1);
    setSelectedCertifications(copy);
  };

  const saveDraft = async () => {
    setDraftLoading(true);
    if (draftMode) {
      await DraftApi.deleteDraft(decodedId);
    }
    DraftApi.add({
      type: "add-job-position",
      title: title,
      payload: {
        title: title,
        availability: position,
        description: description,
        experience: experience,
        // start_date: startDate,
        hiring_status: hiringStatus,
        instagram_required: requireInstagram ? 1 : 0,
        wage: wage,
        resume_required: uploadResume ? 1 : 0,
        certifications: selectedCertifications
          .map((certification) => certification.id)
          .toString(),
      },
    })
      .then(() => {
        toast.success("Draft Saved");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("That didn't work. Please Try Again.");
        }
      })
      .then(() => {
        setDraftLoading(false);
      });
  };

  const loadDraft = () => {
    DraftApi.getAllByType("add-job-position").then((response) => {
      const selectedDraft = response?.data?.data?.find(
        (item) => item.id == decodedId
      );
      if (selectedDraft) {
        setFromDraft(selectedDraft);
      }
    });
  };

  const setFromDraft = (draft) => {
    const payload = JSON.parse(draft.payload);
    setTitle(payload.title);
    setStartDate(payload.start_date);
    setPosition(payload.availability);
    setDescription(payload.description);
    setExperience(payload.experience);
    setWage(payload.wage);
    // Clear draft
    // setDraft(null);
  };

  const loadJob = () => {
    setLoading(true);
    JobPosition.getById(decodedId)
      .then((response) => {
        response = response.data.data;
        setTitle(response.title);
        setStartDate(dayjs(response.start_date).format("YYYY-MM-DD"));
        setDescription(response.description);
        setExperience(response.experience);
        setPosition(response.availability);
        setSelectedCertifications(response.certifications);
        setWage(response.wage);
        setRequireInstagram(response.instagram_required);
        setUploadResume(response.resume_required);
        setHiringStatus(response.hiring_status);
        setPosition(response.availability);
        if (response?.questions !== null) {
          const parsedQuestions = JSON.parse(response?.questions);
          setQuestions(
            parsedQuestions?.map((q, index) => ({
              id: index + 1,
              title: `Question ${index + 1}`,
              description: q.description,
              requirement: q.requirement,
            }))
          );
        }
        if (!response?.start_date) {
          setStartDateType("range");
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2);
    setMinDate(tomorrow);
    if (draftMode) {
      loadDraft();
    }
  }, []);

  useEffect(() => {
    loadPredefinedJobs();
    loadCertifications();
    if (editMode) {
      document.title = "HeyHire Portal-Edit Position";
      loadJob();
    } else {
      document.title = "HeyHire Portal-Create New Position";
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setFields = (job) => {
    setTitle(job?.title);
    setStartDate(dayjs(job?.start_date).format("YYYY-MM-DD"));
    setPosition(job?.availability);
    setDescription(job?.description);
    setExperience(job?.experience);
    if (job?.certifications) {
      setSelectedCertifications(job?.certifications);
    }
    if (!job?.start_date) {
      setStartDateType("range");
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <ConfirmationModal
        openTrigger={openDeleteConfirmationModal}
        closeTrigger={() => setOpenDeleteConfirmationModal(false)}
        type="error"
        title="Confirm Action"
        confirmText="Delete"
        selectedId={questionID}
        description="Are you sure you want to delete this question?"
        confirm={handleRemoveQuestion}
      />

      <div className="mt-8 w-full max-w-7xl">
        <h2 className="text-xl md:text-2xl font-bold text-black dark:text-white">
          {editMode ? "Edit" : "Post"} Position
        </h2>
        <hr className="lg:w-11/12 2xl:w-4/5 border border-primary-100 dark:border-warmgray-300 mt-1" />

        <div className="flex font-bold mt-4">
          <input
            className="mr-2 ml-1 cursor-pointer my-auto"
            type="checkbox"
            defaultChecked={selectJobPosition}
            value={selectJobPosition}
            onChange={() => setSelectJobPosition(!selectJobPosition)}
          />
          <span className="text-black dark:text-white">
            Select Position from Pre-defined Jobs list.
          </span>
        </div>
        {selectJobPosition && prejobs?.length > 0 ? (
          <div className="mt-4 lg:w-[90%] xl:w-4/5">
            <FormSelect
              type="add-job"
              label="Pre-Defined Jobs"
              placeholder="Select from Pre-defined jobs"
              value={selectedJob?.title}
              options={prejobs.map((job) => ({
                value: job?.id,
                label: job?.title,
              }))}
              onChange={(value) => {
                const _job = prejobs?.filter(
                  (item) => item.title === value.label
                );
                setSelectedJob(_job[0]);
                setFields(_job[0]);
              }}
              darmo={mode}
            />
          </div>
        ) : selectJobPosition && prejobs?.length === 0 ? (
          <div className="font-bold text-sm text-gray-800 dark:text-gray-500 mt-2">
            Sorry! No pre-defined jobs found for this Category.
          </div>
        ) : null}

        <div className="text-left snap-start pt-6 pb-24 w-full">
          <div className="lg:w-[90%] xl:w-4/5">
            <Input
              name="title"
              label="Position Name"
              type="text"
              value={title}
              placeholder="Position Name"
              error={
                validationErrors && validationErrors.title
                  ? validationErrors.title[0]
                  : null
              }
              onChange={(e) => setTitle(e.target.value)}
              darmo={mode}
            />
          </div>

          <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-2 lg:w-[90%] xl:w-4/5">
            <div className="mb-4 md:mb-0">
              <FormSelect
                type={"add-job"}
                label={t("pre-defined-jobs.type-of-position")}
                name="company"
                onChange={(value) => {
                  setPosition(value.value);
                }}
                error={
                  validationErrors && validationErrors.availability
                    ? validationErrors.availability[0]
                    : null
                }
                darmo={mode}
                options={availabilityList}
                defaultValue={position}
                placeholder={
                  availabilityList[
                    availabilityList.findIndex((obj) => obj.value === position)
                  ]?.label
                }
              />
            </div>

            <FormSelect
              type={"add-job"}
              label={t("pre-defined-jobs.hiring-status")}
              name="hiring_status"
              onChange={(value) => {
                setHiringStatus(value.value);
              }}
              error={
                validationErrors && validationErrors.hiring_status
                  ? validationErrors.hiring_status[0]
                  : null
              }
              options={hiringStatusList}
              darmo={mode}
              defaultValue={hiringStatus}
              placeholder={
                hiringStatusList[
                  hiringStatusList.findIndex(
                    (obj) => obj.value === hiringStatus
                  )
                ]?.label
              }
            />
          </div>

          <div className="lg:w-[90%] xl:w-4/5">
            <label className="font-bold text-black dark:text-white">
              Position Description
            </label>
            <div className="pt-2">
              <ReactQuill
                className={`${mode ? "quill-light" : "quill-dark"}`}
                theme="snow"
                label={t("pre-defined-jobs.description")}
                value={description}
                onChange={setDescription}
                placeholder="Enter position description"
              />
              {validationErrors && validationErrors.description && (
                <div className="text-red-400 text-xs font-bold my-2">
                  {validationErrors.description[0]}
                </div>
              )}
            </div>
          </div>

          <div className="my-4 lg:w-[90%] xl:w-4/5">
            <label className="font-bold text-gray-900 dark:text-white">
              Requirements
            </label>
            <div className="pt-2">
              <ReactQuill
                className={`${mode ? "quill-light" : "quill-dark"}`}
                theme="snow"
                label="Requirements"
                value={experience}
                onChange={setExperience}
                darmo={mode}
                placeholder="Requirements"
              />
            </div>
          </div>

          <div className="lg:w-[90%] xl:w-4/5">
            <div className="mb-2 md:mb-0">
              <label className="font-bold text-black dark:text-white">
                Required Certifications (Food Handlers, Servsafe, etc...)
              </label>
              <div className="mt-[3px]">
                <ReactTags
                  tags={selectedCertifications}
                  suggestions={certifications}
                  onDelete={removeCertification}
                  onAddition={addCertification}
                  newTagText={"Add New Certification:"}
                  placeholderText={"Search Certifications or add your own"}
                  allowNew={true}
                  allowBackspace={false}
                  classNames={{
                    root: `p-3 border rounded-xl border-gray-300 focus-within:border-primary-300 shadow-sm bg-white dark:bg-slate-800`,
                    searchWrapper: "",
                    searchInput: `focus:outline-0 bg-white dark:bg-slate-800 text-black dark:text-white custom-search-input sm:text-sm border border-gray-100`,
                    rootFocused: "focus:bg-blue-500",
                    suggestions:
                      "p-3 min-w-[250px] z-30 bg-white flex shadow-lg cursor-pointer",
                    suggestionActive: "bg-red-500",
                  }}
                  tagComponent={({ tag, removeButtonText, onDelete }) => {
                    return (
                      <button
                        className={
                          "bg-primary-400 hover:bg-primary-500 text-white text-sm rounded-full py-1 pl-3 pr-2 mr-1 mb-2"
                        }
                        onClick={onDelete}
                      >
                        {tag.name} <i className={"fas fa-times-circle ml-1"} />
                      </button>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-4 lg:w-[90%] xl:w-4/5">
            <label className="font-bold text-gray-900 dark:text-white">
              Compensation
            </label>
            <div className="pt-2">
              <ReactQuill
                className={`${mode ? "quill-light" : "quill-dark"}`}
                theme="snow"
                label="Compensation"
                value={wage}
                onChange={setWage}
                darmo={mode}
                placeholder="Compensation"
              />
            </div>
          </div>

          <div className="mt-6 lg:w-[90%] xl:w-4/5 flex items-center">
            <input
              className="cursor-pointer"
              type="checkbox"
              checked={requireInstagram}
              onChange={() => setRequireInstagram(!requireInstagram)}
            />
            <i
              className={`fab fa-instagram ml-3 text-xl ${
                mode ? "text-primary-500" : "text-gray-500"
              }`}
            ></i>
            <span className="ml-1.5 font-bold text-gray-900 dark:text-gray-300">
              Require Instagram
            </span>
            <div
              className="relative ml-2 text-gray-500 dark:text-gray-400"
              data-tooltip-id="instagram"
              data-tooltip-content="Require Job Seeker to share Instagram feed to apply for this position"
              data-tooltip-place="right"
            >
              <i className="fas fa-info-circle cursor-pointer text-lg"></i>
              <Tooltip
                id="instagram"
                style={{
                  maxWidth: "300px",
                  fontSize: "12px",
                }}
              />
            </div>
          </div>

          <div className="mt-4 lg:w-[90%] xl:w-4/5 flex items-center">
            <input
              className="cursor-pointer"
              type="checkbox"
              checked={uploadResume}
              onChange={() => setUploadResume(!uploadResume)}
            />
            <i
              className={`far fa-file-user ml-3 text-xl ${
                mode ? "text-primary-500" : "text-gray-500"
              }`}
            ></i>
            <span className="ml-2 font-bold text-gray-900 dark:text-gray-300">
              Require Applicant to upload Resume
            </span>
            <div
              className="relative ml-2 text-gray-500 dark:text-gray-400"
              data-tooltip-id="upload-resume"
              data-tooltip-content="Require Job Seeker to upload their resume to apply for this position"
              data-tooltip-place="right"
            >
              <i className="fas fa-info-circle cursor-pointer text-lg"></i>
              <Tooltip
                id="upload-resume"
                style={{
                  maxWidth: "300px",
                  fontSize: "12px",
                }}
              />
            </div>
          </div>

          <hr className="border-[#F6F6F6] dark:border-warmgray-200/10 border-2 my-4 lg:w-[90%] xl:w-4/5" />

          <div className="pb-2 lg:w-[90%] xl:w-4/5">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              Job Application Screening Questions
            </h2>
            <p className="text-sm leading-6 text-gray-600 dark:text-gray-500">
              Screen applicants with targeted questions before they complete
              their application.
            </p>

            <div className="mt-4">
              {questions?.map((question) => (
                <ul role="list" key={question.id}>
                  <li className="flex items-center justify-between gap-x-6 py-5 border-b border-gray-300 dark:border-darkGray">
                    {editingQuestionId === question.id ? (
                      <div className="w-full">
                        <TextInputWithDropdown
                          title={question.title}
                          description={question.description}
                          requirement={question.requirement}
                          onCreate={handleUpdateQuestion}
                          isEditMode={"true"}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="min-w-0">
                          <div className="flex items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300">
                              {question?.title}
                            </p>
                            {question?.requirement === "Required" ? (
                              <p className="mt-0.5 whitespace-nowrap rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Required
                              </p>
                            ) : (
                              <p className="mt-0.5 whitespace-nowrap rounded-md bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                Optional
                              </p>
                            )}
                          </div>
                          <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                            {question?.description}
                          </div>
                        </div>

                        <div className="flex flex-none items-center gap-x-4">
                          <div className="relative flex-none">
                            <button
                              type="button"
                              className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900 dark:hover:text-gray-400"
                              onClick={() => toggleDropdown(question.id)}
                            >
                              <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                              </svg>
                            </button>

                            {openDropdownId === question.id && (
                              <div
                                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                                role="menu"
                                aria-orientation="vertical"
                                ref={dropdownRef}
                              >
                                <a
                                  href="#"
                                  className="block px-3 py-1 text-sm leading-6 text-gray-900"
                                  role="menuitem"
                                  onClick={() => handleEditQuestion(question)}
                                >
                                  Edit
                                </a>
                                <button
                                  className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900"
                                  onClick={() => deleteQuestion(question.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              ))}

              {!showQuestionInput ? (
                <button
                  className="mt-2 cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  onClick={addQuestion}
                >
                  <img
                    src={addIcon}
                    alt="logo"
                    className="mr-2 my-auto w-4 h-4"
                  />
                  Add Question
                </button>
              ) : (
                <TextInputWithDropdown
                  title={`Question ${questions.length + 1}`}
                  onCreate={handleCreateQuestion}
                />
              )}

              {/* Tip section */}
              <div className="mt-3 flex items-center text-xs text-warmgray-600 dark:text-gray-500 my-auto">
                <i className="far fa-info-circle text-base mr-2 my-auto"></i>
                Tip: For better results, we suggest asking up to 3 key
                questions.
              </div>
            </div>
          </div>

          <hr className="border-[#F6F6F6] dark:border-warmgray-200/10 border-2 my-4 lg:w-[90%] xl:w-4/5" />

          <div className="flex w-full mt-8 justify-center lg:w-[90%] xl:w-4/5">
            <button
              className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addJobPosition();
              }}
              disabled={loadingButton}
            >
              <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
              {editMode ? t("pre-defined-jobs.update-job") : "Post Position"}
              {loadingButton && (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              )}
            </button>

            {/* <div className="text-center">
              {!editMode && (
                <button
                  onClick={saveDraft}
                  className={`px-6 border mr-1 flex items-center justify-center bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
                >
                  Save as draft
                </button>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddJobPosition;
