import { Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { useCustomEventListener } from "react-custom-events";
import ReactImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../../config";
import { getFormattedData } from "../../../../utils";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import Category from "../../../../api/Category";
import Auth from "../../../../api/auth";
import FormInput from "../../../../components/atoms/FormInput";
import FormInputWithSuffix from "../../../../components/atoms/FormInputWithSuffix";
import FormSelect from "../../../../components/atoms/FormSelect";
import FormTextarea from "../../../../components/atoms/FormTextarea";
import PlacesAutocomplete from "../../../../components/form/PlacesAutocomplete";
import GoogleMaps from "../../../../components/atoms/GoogleMaps";
import Payment from "../../../../api/Payment";
import Brand from "../../../../api/Brand";
import {
  RegisterStep2Schema,
  RegisterStep2InitialValues,
} from "../registerSchema";
import { darkmode, setStripeCustomerId } from "../../../../store/authSlice";
import CustomNotification from "../../../../utils/CustomNotification";
import ProgressBar from "../../../../components/atoms/ProgressBar/ProgressBar";

const googleKey = config.GOOGLE_API;

const Step2 = ({ onSubmit, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;
  const [manualAddress, setManualAddress] = useState(false);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [addressData, setAddressData] = useState(
    JSON.parse(localStorage.getItem("registerStep2"))?.addressData || {}
  );
  const [completeAddress, setCompleteAddress] = useState("");
  const [isAddressDescription, setAddressDescription] = useState(false);
  const [file, setFile] = useState("/images/register_logo.svg");
  const [imageError, setImageError] = useState(false);
  const [images, setImages] = useState([]);
  const [businessContact, setBusinessContact] = useState("");
  const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "https://staging.heyhire.app"
    : "https://heyhire.app";

  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const mode = useSelector(darkmode);

  const { placesService, placePredictions } = usePlacesService({
    apiKey: googleKey,
    libraries: ["places", "geometry", "name"],
  });

  // fetch Business categories
  const GetBusinessCategories = async () => {
    let catData = [];
    const categories = await Category.getAll();
    const c = categories.data.data;
    for (let i = 0; i < c.length; i++) {
      const el = c[i];
      catData.push({
        label: el.name,
        value: el.id,
      });
    }
    setCategories(catData);
  };

  //place prediction
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      placesService?.getDetails(
        { placeId: placePredictions[0].place_id },
        (placeDetails) => {
          const cityD = getFormattedData(
            placeDetails["address_components"],
            "locality"
          );
          const State = getFormattedData(
            placeDetails["address_components"],
            "administrative_area_level_1"
          );
          const ZipCode = getFormattedData(
            placeDetails["address_components"],
            "postal_code"
          );
          const address = {
            address: placeDetails.formatted_address,
            city: cityD?.long_name,
            state: State?.long_name,
            zip: ZipCode?.long_name,
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          };
          setAddressData(address);
        }
      );
    }
  }, [placePredictions]);

  // intial load
  useEffect(() => {
    if (
      !data?.businessName &&
      !data?.firstName &&
      !data?.lastName &&
      !data?.email &&
      !data?.phone &&
      !data?.password &&
      !data?.cpassword &&
      !data?.hearAboutUs
    )
      return navigate("/plans");
    GetBusinessCategories();
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    setErrors,
    touched,
    setValues,
  } = useFormik({
    initialValues: RegisterStep2InitialValues,
    validationSchema: RegisterStep2Schema,
    onSubmit: async (formData) => {
      setSubmitting(true);
      const APIData = {
        address: addressData,
        location: {
          name: manualAddress ? values.locationName : addressData.name,
          url_slug: values.customUrl,
          category_id: values.businessCategory,
          description: values.businessDescription,
          business_phone: businessContact,
        },
        company: {
          name: data.businessName,
          how_did_you_hear:
            data?.hearAboutUs === "Others" ? data?.others : data?.hearAboutUs,
        },
        user: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone: data.phone,
          password: data.password,
        },
      };

      return RegisterUser(APIData);
    },
  });

  const RegisterUser = async (dataAPi) => {
    try {
      setErrors(null);
      if (file === "/images/register_logo.svg") {
        setImageError(true);
        return;
      } else {
        await Auth.Register(dataAPi)
          .then(async (reqData) => {
            localStorage.setItem("token", reqData.data.token);
            localStorage.removeItem("registerStep0");
            localStorage.removeItem("registerStep1");
            localStorage.removeItem("registerStep2");

            Payment.addAccount(reqData.data).then((response) => {
              dispatch(setStripeCustomerId(response?.data?.id));
            });

            setShowNotification({
              show: true,
              type: "success",
              message: "Registeration Successfully!",
            });

            localStorage.removeItem("registerStep0");
            localStorage.removeItem("registerStep1");
            localStorage.removeItem("registerStep2");
            localStorage.removeItem("selectedPlan");

            try {
              await Brand.uploadImage(reqData?.data?.brand?.id, file);
            } catch (error) {
              console.error("Error uploading brand image:", error);
              setShowNotification({
                show: true,
                type: "error",
                message: "Error uploading brand image. Please try again.",
              });
            }

            try {
              const paymentResponse = await Payment.addAccount(reqData.data);
              const request = {
                plan_id: selectedPlan?.plan_id,
                customer_stripe_id: paymentResponse?.data?.id,
              };
              const result = await Payment.getRedirectURL(request);
              const paymentUrl = result?.data?.url?.url;
              if (paymentUrl) {
                window.location.href = paymentUrl;
              } else {
                console.error("Payment URL is not available");
              }
            } catch (error) {
              console.error("Error handling payment redirection:", error);
              setShowNotification({
                show: true,
                type: "error",
                message:
                  "Error handling payment redirection. Please try again.",
              });
              navigate(0);
            }
          })
          .catch(() => {
            setShowNotification({
              show: true,
              type: "error",
              message: "Error creating new business! please contact service.",
            });
          });
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        if (error?.response?.data?.errors["location.url_slug"]) {
          const _error = error?.response?.data?.errors["location.url_slug"];
          setErrors({ ...errors, customUrl: _error[0] });
        } else if (error?.response?.data?.errors["location.name"]) {
          const _error = error?.response?.data?.errors["location.name"];
          setErrors({ ...errors, businessAddress: _error[0] });
        }
      } else {
        setShowNotification({
          show: true,
          type: "error",
          message: "Api Error!",
        });
      }
    }
  };

  const handleInput = (e) => {
    if (e.target.value === "") {
      setAddressDescription(false);
    }
  };

  useCustomEventListener("placeSelected", (data) => {
    const Name = data.terms[0]?.value;
    const cityD = data.terms[data.terms.length - 3]?.value;
    const State = data.terms[data.terms.length - 2]?.value;
    const ZipCode = data.zipCode ? data.zipCode : "00000";
    const addressLineCustom = data.addressLine.replaceAll(",", "");
    const address = {
      name: Name,
      address: data.description,
      city: cityD,
      state: State,
      zip: ZipCode,
      lat: data.lat,
      lng: data.lng,
    };
    setBusinessContact(data.business_number);
    setAddressData(address);
    setCompleteAddress(data.description);
    setAddressDescription(true);
    setFieldValue("businessAddress", data.addressLine);
    const _customUrl = addressLineCustom.toLowerCase().replaceAll(" ", "-");
    form.setFieldsValue({
      customUrl: _customUrl,
    });
    setFieldValue("customUrl", _customUrl);
  });

  const setMapSelectAddress = (address) => {
    setAddressData(address);
    setFieldValue("businessAddress", address?.address);
  };

  const initializeFormValues = () => {
    const storedData = localStorage.getItem("registerStep2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setValues(parsedData);
      form.setFieldsValue(parsedData);
      if (parsedData.images) {
        setImages(parsedData.images);
        setFile(parsedData.images[0]?.file);
        setImageError(false);
      } else {
        setImages([]);
      }
    }
  };

  useEffect(() => {
    initializeFormValues();
  }, []);

  return (
    <>
      <AuthLayout>
        <ProgressBar currentStep={3} />
        <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
            <div className="w-full h-full">
              <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
                <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                  <div className="flex flex-col h-full items-center sm:rounded-lg sm:px-12 lg:px-6">
                    <p
                      className={`text-xl font-bold ${
                        mode ? "text-black" : "text-white"
                      }`}
                    >
                      Provide Business Details
                    </p>
                    <Form
                      onFinish={handleSubmit}
                      layout="vertical"
                      className="mt-6 w-full"
                      form={form}
                    >
                      <ReactImageUploading
                        value={images}
                        onChange={(imageList, index) => {
                          setImageError(false);
                          setFile(imageList[0]?.file);
                          setImages(imageList);
                        }}
                      >
                        {({ imageList, onImageUpload }) => (
                          <div className="flex items-center mb-7">
                            <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center">
                              {imageList && imageList.length > 0 ? (
                                <img
                                  className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                  src={imageList[0].dataURL}
                                />
                              ) : (
                                <i
                                  className="text-white text-3xl self-center fas fa-building cursor-pointer"
                                  onClick={onImageUpload}
                                />
                              )}
                            </div>
                            <div className="ml-5">
                              <button
                                onClick={onImageUpload}
                                type="button"
                                className={`border rounded-[4px] ${
                                  mode
                                    ? "text-indigo-600 border-indigo-600"
                                    : "text-white border-white"
                                } h-10 px-4 mb-[11px]`}
                              >
                                Upload Logo
                              </button>
                              <div
                                className={`font-bold text-[12px] ${
                                  imageError
                                    ? "text-red-400"
                                    : mode
                                    ? "text-gray-900"
                                    : "text-white"
                                }`}
                              >
                                Upload your business profile logo
                              </div>
                            </div>
                          </div>
                        )}
                      </ReactImageUploading>

                      <Space
                        size={20}
                        direction="vertical"
                        className="relative w-full"
                      >
                        <>
                          <PlacesAutocomplete
                            label={`Business ${
                              manualAddress ? t("common.address") : "Name"
                            }`}
                            placeholder={
                              manualAddress
                                ? t("common.address-example")
                                : "Enter Business Name  "
                            }
                            pValue={values.businessAddress}
                            isAddressSearch={manualAddress}
                            setAddressCheck={handleInput}
                            error={
                              errors?.businessAddress
                                ? errors?.businessAddress
                                : null
                            }
                            darmo={mode}
                          />
                          {manualAddress ? (
                            <FormInput
                              id="locationName"
                              name="locationName"
                              label="Location Name"
                              placeholder="XYZ Holdings LLC"
                              value={values.locationName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              darmo={mode}
                            />
                          ) : null}
                          {!manualAddress ? (
                            addressData?.address && isAddressDescription ? (
                              <div
                                className={`text-[12px] mt-[-10px] px-3 ${
                                  mode ? "text-black" : "text-white"
                                }`}
                              >
                                <i className="fas fa-map-marker-alt" />{" "}
                                {completeAddress}
                              </div>
                            ) : (
                              <a
                                href="#"
                                onClick={() => setManualAddress(true)}
                                className={`${
                                  mode
                                    ? "text-indigo-600 hover:text-indigo-500"
                                    : "text-secondary hover:text-white"
                                } font-semibold`}
                              >
                                Enter Business Address manually?
                              </a>
                            )
                          ) : (
                            <GoogleMaps
                              location={{
                                address: addressData?.address,
                                lat: addressData?.lat,
                                lng: addressData?.lng,
                              }}
                              onClickAction={setMapSelectAddress}
                            />
                          )}
                        </>

                        <FormInputWithSuffix
                          id="customUrl"
                          name="customUrl"
                          label="Custom URL"
                          addonBefore={apiUrl}
                          value={values.customUrl}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          darmo={mode}
                          setFieldValue={setFieldValue}
                        />
                        {touched?.customUrl && errors?.customUrl && (
                          <p className="text-red-400 text-xs mt-[-14px] font-bold">
                            {errors?.customUrl}
                          </p>
                        )}

                        <FormSelect
                          name="businessCategory"
                          label="Select Business Category"
                          description="Select the category that most closely matches your business type. When you post a new position, we'll notify users interested in working with your type of business."
                          placeholder="List business categories"
                          defaultValue={
                            !!values.businessCategory && [
                              {
                                label:
                                  categories[values.businessCategory - 1]
                                    ?.label,
                                value:
                                  categories[values.businessCategory - 1]
                                    ?.value,
                              },
                            ]
                          }
                          value={
                            !!values.businessCategory && [
                              {
                                label:
                                  categories[values.businessCategory - 1]
                                    ?.label,
                                value:
                                  categories[values.businessCategory - 1]
                                    ?.value,
                              },
                            ]
                          }
                          onBlur={handleBlur}
                          onChange={(value) =>
                            setFieldValue("businessCategory", value.value)
                          }
                          options={categories}
                          darmo={mode}
                        />
                        {touched?.businessCategory &&
                          errors?.businessCategory && (
                            <p className="text-red-400 text-xs font-bold mt-[-22px]">
                              {errors?.businessCategory}
                            </p>
                          )}

                        <FormTextarea
                          name="businessDescription"
                          label="Describe Your Business"
                          placeholder="You can talk about your culture, service, mission or anything that best describes your business"
                          value={values.businessDescription}
                          onBlur={handleBlur}
                          callBack={(value) =>
                            setFieldValue("businessDescription", value)
                          }
                          darmo={mode}
                        />
                        {touched?.businessDescription &&
                          errors?.businessDescription && (
                            <p className="text-red-400 text-xs font-bold mt-[-14px]">
                              {errors?.businessDescription}
                            </p>
                          )}
                      </Space>
                      <div
                        className={`title mt-12 text-sm ${
                          mode ? "text-black" : "text-primary-100"
                        }`}
                      >
                        By registering, you are agreeing to the
                        <Link
                          to="https://www.heyhire.com/terms-conditions"
                          target="_blank"
                          className={`${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold ml-1`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and
                        <Link
                          to="https://www.heyhire.com/privacy"
                          target="_blank"
                          className={`ml-1 ${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold`}
                        >
                          Privacy Policy
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="flex border-t-2 py-4 border-primary-500 dark:border-darkGray justify-center">
                  <div className="flex w-full lg:w-1/2 xl:w-1/3 justify-between">
                    <button
                      className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                      type="button"
                      onClick={() => {
                        const formData = { ...values, images, addressData };
                        localStorage.setItem(
                          "registerStep2",
                          JSON.stringify(formData)
                        );
                        navigate("/register?step=2");
                      }}
                      disabled={isSubmitting}
                    >
                      Previous
                    </button>
                    <button
                      className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                      type="submit"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Finish
                      {isSubmitting ? (
                        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
    </>
  );
};

export default Step2;
