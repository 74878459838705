import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Application from "../../api/Application";
import JobPosition from "../../api/JobPosition";
import User from "../../api/User";
import { DashboardLayout } from "../../components/organisms/Layout";
import SliderWrapper from "../../components/molecules/SliderWrapper";
import ApplicationLogs from "../../api/ApplicationLogs";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";
import Loading from "../../components/Loading";
import CardLoading from "../../components/Loading/CardLoading";
import { DropActionButtonDropdown } from "../../components/TableActionDropdown";
import ArchivedJob from "../../api/ArchivedJob";
import { decodeIds, encodeIds } from "../../utils";
import {
  applicantsIcon,
  declineIcon,
  hiredIcon,
  pendingIcon,
  positionIcon,
} from "../../assets/images";
import "../../pages/Shifts/ViewShift.scss";
import NewConfirmationModal from "../../components/modals/NewConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { setJobPositionTitle, sidebar } from "../../store/authSlice";
import ShiftApplicantCard from "../../components/ShiftApplicantCard";
import ShiftJobCard from "../../components/molecules/ShiftJobCard/ShiftJobCard";

const CANCELED = "canceled";
const REJECTED = "rejected";
const ACTIVE = "applied";
const employees = [];

const ViewEvent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isApplicantsLoading, setApplicantsLoading] = useState(true);
  const [active, setActive] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [jobPosition, setJobPosition] = useState({});
  const [jobPositions, setJobPositions] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [decodedId, setDecodedId] = useState(decodeIds(params?.id)[0]);
  const [showMoreGeneral, setShowMoreGeneral] = useState(false); //Benji wanted me to make the buttons control both of the paragraphs and not control each on a general level. The other functions become obsolete as a result. Didnt remove them because dont know if you guys wanna handle this some other way.
  const [filteredApplicants, setFilteredApplicants] = useState("all");
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const open = useSelector(sidebar);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;

      if (isMobile) {
        setFilteredApplicants("pending");
      } else {
        setFilteredApplicants("all");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (direction) => {
    const scrollContainer = scrollContainerRef.current;
    const scrollStep = 100;

    if (direction === "left") {
      scrollContainer.scrollLeft -= scrollStep;
    } else if (direction === "right") {
      scrollContainer.scrollLeft += scrollStep;
    }
  };

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadJobPositions();
    //     loadApplications();
    //     loadEmployees();
    //     uploadApplicationEvent();
  }, [decodedId]);

  useEffect(() => {
    setDecodedId(decodeIds(params?.id)[0]);
  }, [location]);

  return (
    <DashboardLayout darmo={true} bgColor="bg-white">
      <div className="m-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex-col my-auto hidden md:flex gap-2">
            <h4 className="font-bold text-2xl text-gray-900 dark:text-warmgray-400">
              Event:{" "}
              <span className="text-primary-500 dark:text-gray-300">
                Hollander Capgemini Party
              </span>
            </h4>
            <div className="text-[14px] font-arial dark:text-gray-300">
              <span className="font-bold">Date:</span> Friday February 12th /
              Sunday February 14th
            </div>
            <h4 className="font-bold text-xl dark:text-warmgray-400">
              Open Shifts (3)
            </h4>
          </div>

          <div className="my-4 block md:hidden w-full text-center">
            <div className="flex items-center justify-center">
              <hr className="border-primary-500 flex-grow mr-2" />
              <h4 className="font-bold text-xl text-gray-900 dark:text-warmgray-400">
                Event:{" "}
                <span className="text-primary-500 dark:text-gray-300">
                  Hollander Capgemini Party
                </span>
              </h4>
              <hr className="border-primary-500 flex-grow ml-2" />
            </div>
          </div>

          <div className="h-min flex items-center">
            <DropActionButtonDropdown
              type="event"
              menus={[
                {
                  label: "Edit Event",
                  action: () => navigate("/job-positions/edit/" + params?.id),
                },
                {
                  label: `${
                    jobPosition?.archived_at != null
                      ? "Restore Event"
                      : "Archive Event"
                  }`,
                  //   action: () => handleArcheive(),
                },
                // {
                //   label: "Delete Position",
                //   action: () => handleDelete(),
                //   disabled: jobPosition.deleted_at != null,
                // },
              ]}
            />
          </div>
        </div>

        <div className="md:hidden flex flex-col xl:flex-row w-full gap-3 mt-6">
          <div className="flex gap-1 justify-between px-4 py-2 border-2 border-gray-300 overflow-auto rounded-md bg-white xl:w-1/2">
            <div>
              <h6 className="mb-1">
                <span className="font-bold">Open Shifts</span>{" "}
                <span className="font-arial">(3)</span>
              </h6>
            </div>

            <div>
              <h6 className="mb-1">
                <span className="font-bold">Shift Date:</span>{" "}
                <span className="font-arial">Friday February 12th</span>
              </h6>
            </div>
          </div>
        </div>

        <hr className="border-primary-500 w-full mt-6 block md:hidden" />
        <hr className="border-primary-500 w-full mt-2 block md:hidden" />

        {loading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-orange-500 border-t-transparent"></div>
          </div>
        ) : (
          <div
            className={`mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 ${
              !open && "2xl:grid-cols-4"
            }`}
          >
            {jobPositions?.map((jb, i) => (
              <ShiftJobCard
                key={i}
                jobPosition={jb}
                loadJobs={loadJobPositions}
                darmo={true}
              />
            ))}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ViewEvent;
