import React from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { useNavigate } from "react-router-dom";

const MainShiftsPage = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="mt-8 w-full max-w-7xl px-4 mx-auto pb-8">
        <div className="text-center mx-auto mb-12">
          <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold text-gray-900 dark:text-gray-300 mb-4">
            Let's get started...
          </h2>
          <p className="text-base text-gray-600 dark:text-gray-500">
            Post a shift to find local job seekers that are open for last minute
            shifts on HeyHire now Network!
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h2 className="text-xl font-bold mb-8 text-gray-900 dark:text-gray-300">
            Choose Your Posting Type
          </h2>

          <div className="grid md:grid-cols-2 gap-8 w-full max-w-4xl">
            {/* First Card - Single Shift */}
            <div
              className="group bg-primary-500 dark:bg-[#242526] rounded-xl p-6 sm:p-8 cursor-pointer transition-all duration-300 hover:shadow-lg relative overflow-hidden h-full"
              onClick={() => navigate("/shifts/add")}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>

              <div className="flex flex-col items-center text-white relative z-10 h-full">
                <div className="mb-6 transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 sm:w-24 sm:h-24 bg-white/20 rounded-full flex items-center justify-center">
                    <svg
                      className="w-10 h-10 sm:w-12 sm:h-12 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                </div>
                <h3 className="text-lg sm:text-xl xl:text-2xl font-bold mb-3 sm:mb-4 text-gray-100">
                  Single Shift
                </h3>
                <p className="text-center text-white/90 flex-grow">
                  Perfect for posting an individual shift opportunity. Quick and
                  straightforward process.
                </p>
                <div className="bg-white/20 px-4 sm:px-6 py-2 rounded-full text-sm inline-block group-hover:bg-white/30 transition-colors duration-300 mt-6">
                  Get started →
                </div>
              </div>
            </div>

            {/* Second Card - Multiple Shifts */}
            <div
              className="group bg-primary-500 dark:bg-[#242526] rounded-xl p-6 sm:p-8 cursor-pointer transition-all duration-300 hover:shadow-lg relative overflow-hidden h-full"
              onClick={() => navigate("/shifts/event/add")}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>

              <div className="flex flex-col items-center text-white relative z-10 h-full">
                <div className="mb-6 transition-transform duration-300 group-hover:scale-110">
                  <div className="w-20 h-20 sm:w-24 sm:h-24 bg-white/20 rounded-full flex items-center justify-center">
                    <svg
                      className="w-10 h-10 sm:w-12 sm:h-12 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>
                </div>
                <h3 className="text-lg sm:text-xl xl:text-2xl font-bold mb-3 sm:mb-4 text-gray-100">
                  Multiple Shifts
                </h3>
                <p className="text-center text-white/90 flex-grow">
                  Ideal for events or projects requiring multiple staff members
                  across different times.
                </p>
                <div className="bg-white/20 px-4 sm:px-6 py-2 rounded-full text-sm inline-block group-hover:bg-white/30 transition-colors duration-300 mt-6">
                  Get started →
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MainShiftsPage;
