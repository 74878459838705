import React from "react";

const TextArea = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  disabled,
  error,
  rows,
  darmo,
}) => {
  return (
    <>
      <label
        className={`font-bold title ${
          darmo ? "text-warmgray-900" : "text-white"
        }`}
      >
        {label}
      </label>
      <div className={`${name === "eventDetails" ? "mt-1" : ""}`}>
        <textarea
          className={`h-24 w-full p-2 sm:text-sm disabled:text-black border-gray-100 border-[1px] rounded-[4px] focus:shadow-none ${
            darmo
              ? "bg-white focus:border-primary-500 placeholder:text-gray-400 hover:border-primary-500 "
              : "bg-slate-800 text-white focus:border-white placeholder:text-gray-300"
          }`}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event)}
          rows={rows ? rows : 5}
          disabled={disabled}
        />
      </div>

      {error ? (
        <div className="text-red-400 text-xs font-bold">{error}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default TextArea;
