import React from "react";
import { useNavigate } from "react-router-dom";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-toastify";
import ArchivedJob from "../../../api/ArchivedJob";
import {
  applicantWhiteIcon,
  hiredWhiteIcon,
  startChatIcon,
} from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setShiftsArray, shiftsArray, sidebar } from "../../../store/authSlice";
import moment from "moment";
import IconSelector from "../../atoms/Selector/IconSelector";
import { RiEyeFill } from "react-icons/ri";

export const ShiftJobCard = ({
  onClick,
  index,
  className,
  type,
  jobPosition,
  loadJobs,
  darmo,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const existingShifts = useSelector(shiftsArray);
  const open = useSelector(sidebar);

  const ArchivePosition = async () => {
    try {
      await JobPosition.archive(jobPosition.id);
      loadJobs();
      toast.success("Job Archived Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const UnarchivePosition = async () => {
    try {
      await ArchivedJob.unachiveJob(jobPosition.id);
      loadJobs();
      toast.success("Job Restored Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const DeletePosition = async () => {
    try {
      await JobPosition.delete(jobPosition.id);
      loadJobs();
      toast.success("Job Deleted Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  return (
    <div className={className} key={index}>
      <div className="group relative overflow-hidden bg-gradient-to-br from-orange-400 to-orange-500 dark:from-[#242526] dark:to-[#242526] rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
        {/* Header with Actions */}
        <div className="relative flex items-center pt-4 px-4">
          <div className="flex-1">
            <h3 className="text-base xl:text-lg font-bold text-white text-center">
              {jobPosition?.title || "test"}
            </h3>
          </div>
          <div className="absolute right-4 top-4">
            <IconSelector
              orientation="vertical"
              color={darmo ? "white" : "gray"}
              menus={[
                {
                  label:
                    type === "event"
                      ? "Delete Shift"
                      : jobPosition?.archived_at != null
                      ? "Restore the Position"
                      : "Archive Position",
                  action:
                    type === "event"
                      ? () =>
                          dispatch(
                            setShiftsArray(
                              existingShifts.filter((_, i) => i !== index)
                            )
                          )
                      : jobPosition?.archived_at != null
                      ? () => UnarchivePosition()
                      : () => ArchivePosition(),
                },
              ]}
            />
          </div>
        </div>

        {/* Content */}
        <div
          className={`p-2 ${
            open ? "p-4 sm:p-3 xl:p-4 2xl:p-6" : "2xl:p-3"
          } text-white/90`}
        >
          {/* Date and Time */}
          <div className="space-y-4 mb-6">
            <div className="flex items-center justify-center space-x-3 bg-white/10 group-hover:bg-white/15 dark:group-hover:bg-white/15 rounded-lg p-3 backdrop-blur-sm transition-all duration-200">
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <span className="font-semibold group-hover:text-white transition-colors">
                {moment(jobPosition?.startDate).format("dddd MMMM Do")}
              </span>
            </div>

            <div className="flex items-center justify-center space-x-3 bg-white/10 group-hover:bg-white/15 dark:group-hover:bg-white/15 rounded-lg p-3 backdrop-blur-sm transition-all duration-200">
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="font-semibold group-hover:text-white transition-colors">
                {jobPosition?.time || "Morning"} Shift
              </span>
            </div>
          </div>

          {type !== "event" && (
            <div className="space-y-4">
              {/* Stats Grid */}
              <div className="grid grid-cols-2 gap-3">
                <div className="bg-white/10 group-hover:bg-white/15 dark:group-hover:bg-white/15 rounded-lg py-3 px-1 backdrop-blur-sm transition-all duration-200">
                  <div className="flex flex-col items-center justify-center text-center h-full">
                    <div className="flex items-center space-x-2">
                      <img
                        src={applicantWhiteIcon}
                        alt="applicant"
                        className="w-4 h-4 flex-shrink-0"
                      />
                      <span
                        className={`${
                          open
                            ? "text-xs 2xl:text-sm"
                            : "text-xs lg:text-sm 2xl:text-xs"
                        } font-bold whitespace-nowrap group-hover:text-white transition-colors`}
                      >
                        Available Shifters
                      </span>
                    </div>
                    <p className="text-lg xl:text-xl font-bold mt-2 group-hover:scale-110 transition-transform">
                      12
                    </p>
                  </div>
                </div>

                <div className="bg-white/10 group-hover:bg-white/15 dark:group-hover:bg-white/15 rounded-lg py-3 px-1 backdrop-blur-sm transition-all duration-200">
                  <div className="flex flex-col items-center justify-center text-center h-full">
                    <div className="flex items-center space-x-2">
                      <img
                        src={startChatIcon}
                        alt="chat"
                        className="w-4 h-4 flex-shrink-0"
                      />
                      <span
                        className={`${
                          open
                            ? "text-xs 2xl:text-sm"
                            : "text-xs lg:text-sm 2xl:text-xs"
                        } font-bold whitespace-nowrap group-hover:text-white transition-colors`}
                      >
                        Active Reachouts
                      </span>
                    </div>
                    <p className="text-lg xl:text-xl font-bold mt-2 group-hover:scale-110 transition-transform">
                      3
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white/10 group-hover:bg-white/15 dark:group-hover:bg-white/15 rounded-lg py-3 px-1 backdrop-blur-sm transition-all duration-200">
                <div className="flex flex-col items-center justify-center text-center">
                  <div className="flex items-center space-x-2">
                    <img
                      src={hiredWhiteIcon}
                      alt="hired"
                      className="w-4 h-4 flex-shrink-0"
                    />
                    <span
                      className={`${
                        open
                          ? "text-xs 2xl:text-sm"
                          : "text-xs lg:text-sm 2xl:text-xs"
                      } font-bold whitespace-nowrap group-hover:text-white transition-colors`}
                    >
                      Confirmed
                    </span>
                  </div>
                  <p className="text-lg xl:text-xl font-bold mt-2 group-hover:scale-110 transition-transform">
                    1/3
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Action Button */}
          <div className="mt-6">
            <button
              // className="w-full flex items-center justify-center gap-2 py-3 bg-white dark:bg-darkGray text-orange-500 dark:text-warmgray-300/100 hover:bg-orange-100 dark:hover:bg-opacity-50 rounded-lg transition-all duration-300 font-medium group-hover:shadow-md"
              className="w-full flex items-center justify-center gap-2 py-3 bg-white/20 hover:bg-white/25 rounded-lg transition-all duration-300 text-white font-medium group-hover:shadow-md"
              onClick={() =>
                type === "event" ? onClick() : navigate("/-now-")
              }
            >
              <RiEyeFill className="text-lg" />
              {type === "event" ? "Edit" : "Manage"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftJobCard;
