import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { encodeIds } from "../utils";
import {
  certificationsGrayIcon,
  certificationsIcon,
  certificationsWhiteIcon,
  defaultUserImage,
  defaultUserImage2,
} from "../assets/images";
import ApplicationLogs from "../api/ApplicationLogs";
import Application from "../api/Application";
import { useSelector } from "react-redux";
import { darkmode } from "../store/authSlice";

const CANCELED_STATUS = ["canceled"];
const REJECTED_STATUS = ["rejected"];

const CertificationBadge = ({ name, type, isDarkMode }) => {
  const getIcon = () => {
    if (type === "employee") return certificationsWhiteIcon;
    return isDarkMode ? certificationsIcon : certificationsGrayIcon;
  };

  return (
    <div className="flex flex-col items-center justify-center p-2 rounded-lg bg-opacity-10 hover:bg-opacity-20 transition-all duration-200 min-w-20">
      <img src={getIcon()} alt={name} className="w-7 h-7 mb-1" />
      <p
        className={`text-xs font-medium ${
          type === "employee"
            ? "text-white"
            : isDarkMode
            ? "text-gray-900"
            : "text-gray-700"
        }`}
      >
        {name}
      </p>
    </div>
  );
};

const ExperienceItem = ({ role, company, period, type }) => (
  <li className="mb-2">
    <div
      className={`text-sm ${
        type === "employee" ? "text-white" : "text-gray-700 dark:text-gray-100"
      }`}
    >
      <span className="font-semibold">
        {company} - {role}
      </span>
      <div className="text-xs opacity-75">{period}</div>
    </div>
  </li>
);

const ShiftApplicantCard = ({
  applicant,
  application,
  type,
  positionId,
  className = "",
}) => {
  const mode = useSelector(darkmode);
  const { t } = useTranslation();

  const handleApplicationView = async () => {
    try {
      await Promise.all([
        Application.postApplicationView({ application_id: application?.id }),
        ApplicationLogs.addEvent({
          event_type: "view",
          entity_type: "application",
          entity_id: application?.id,
        }),
      ]);
    } catch (error) {
      console.error("Failed to record application view:", error);
    }
  };

  const certifications = [
    { id: 1, name: "Food Handlers" },
    { id: 2, name: "Food Safety" },
    { id: 3, name: "TABC" },
  ];

  const isInactive =
    CANCELED_STATUS.includes(application?.status) ||
    REJECTED_STATUS.includes(application?.status) ||
    type === "cancelled";

  return (
    <div
      className={`
      relative rounded-xl border transition-all duration-200
      ${
        type === "employee"
          ? "bg-orange-500 dark:bg-[#3A3B3C] border-orange-900 dark:border-[#3A3B3C]"
          : "bg-white dark:bg-[#2C2D30] border-orange-900 dark:border-[#3A3B3C]"
      }
      ${isInactive ? "opacity-50" : "hover:shadow-lg"}
      ${className}
    `}
    >
      {/* Profile Section */}
      <div
        className={`p-4 border-b ${
          type === "employee"
            ? "border-primary-500 dark:border-warmgray-500"
            : "border-primary-500 dark:border-warmgray-500"
        }`}
      >
        <div className="relative mb-2">
          <img
            src={
              applicant?.photo?.thumb_url ||
              (type === "employee" || !mode
                ? defaultUserImage2
                : defaultUserImage)
            }
            alt={`${applicant?.first_name}'s profile`}
            className="mx-auto h-32 w-32 rounded-full object-cover"
          />
        </div>

        <h3
          className={`text-base font-bold mb-1 text-center ${
            type === "employee"
              ? "text-white"
              : "text-primary-500 dark:text-white"
          }`}
        >
          {applicant?.first_name} {applicant?.last_name || "Ernesto Martinez"}
        </h3>

        <span
          className={`block mt-1 text-center ${
            type === "employee" ? "text-white" : "dark:text-gray-100"
          }`}
        >
          Available for Shift
        </span>

        <span
          className={`flex justify-center items-center mt-1 ${
            type === "employee" ? "text-white" : "dark:text-gray-100"
          }`}
        >
          <i
            className={`fas fa-check-circle text-lg mr-1.5 ${
              type === "employee"
                ? "text-gray-300"
                : "text-orange-500 dark:text-warmgray-300"
            }`}
          />
          <span>Located within (25) mi</span>
        </span>
      </div>

      {/* Certifications Section */}
      <div className="my-2 px-2 mx-auto">
        <div className="flex flex-col justify-center items-center">
          <p
            className={`text-base mb-2 font-bold ${
              type === "employee" ? "text-white" : "dark:text-white"
            }`}
          >
            Certifications:
          </p>
          <div className="flex justify-center items-center flex-wrap gap-4">
            {certifications.map((cert) => (
              <CertificationBadge
                key={cert.id}
                name={cert.name}
                type={type}
                isDarkMode={mode}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Experience Section */}
      <div className="my-2 px-2 mx-auto text-center">
        <div className="flex flex-col justify-center items-center">
          <p
            className={`text-base mb-2 font-bold ${
              type === "employee" ? "text-white" : "dark:text-white"
            }`}
          >
            Experience:
          </p>
          <div
            className={`flex justify-start items-start flex-col text-left text-[14px] ${
              type === "employee" ? "text-white" : "dark:text-gray-100"
            }`}
          >
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li style={{ lineHeight: "1.75" }}>
                <span className="font-bold">Mike's Place - Bartender:</span>{" "}
                09/09/2022 - Present
              </li>
              <li style={{ lineHeight: "1.75" }}>
                <span className="font-bold">Chilli's Barback:</span> 09/09/2023
                - 02/14/2021
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Action Buttons Section */}
      {!isInactive && (
        <div
          className={`flex divide-x divide-warmgray-300 dark:divide-warmgray-500 border-t mt-4 ${
            type === "employee"
              ? "text-white border-warmgray-300 dark:border-warmgray-500"
              : "border-warmgray-300 dark:border-warmgray-500"
          }`}
        >
          <div className="w-1/2">
            <button
              onClick={handleApplicationView}
              className={`
                w-full inline-flex items-center justify-center 
                py-4 text-sm font-semibold
                ${
                  type === "employee"
                    ? "text-white dark:text-warmgray-300"
                    : "text-gray-900 dark:text-warmgray-300"
                }
                `}
            >
              {type === "applicant" ? "Start Chat" : "Open Chat"}
            </button>
          </div>

          <div className="w-1/2">
            <NavLink
              to={
                type === "applicant"
                  ? `/business/applicant/${encodeIds(positionId)}/${encodeIds(
                      applicant?.id
                    )}`
                  : `/business/hired-applicant/${encodeIds(
                      positionId
                    )}/${encodeIds(applicant?.id)}`
              }
            >
              <button
                onClick={handleApplicationView}
                className={`
                w-full inline-flex items-center justify-center py-4 text-sm font-semibold
                ${
                  type === "employee"
                    ? "text-white dark:text-warmgray-300"
                    : "text-gray-900 dark:text-warmgray-300"
                }
                `}
              >
                {type === "applicant"
                  ? "View Profile"
                  : `Invite ${applicant?.first_name || "Ernesto"}`}
              </button>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftApplicantCard;
