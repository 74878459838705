import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { OpenJobs, LineChart } from "../../../assets/images";
import "../ViewJob/ViewJob.scss";
import ApexCharts from "apexcharts";
import FormSelect from "../../../components/atoms/FormSelect";
import {
  conversations,
  darkmode,
  locations,
  selectRoles,
  selectSelectedLocation,
  selectChats,
  selectUser,
} from "../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import ChatApi from "../../../api/Chat";
import { Client } from "@twilio/conversations";

const AnalyticsTable = ({ mode }) => {
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);
  const allLocations = useSelector(locations);
  const showLocationDropdown =
    roles[0] !== "employee" ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const [selectedOption, setSelectedOption] = useState(null);
  const chartRef = useRef(null); // Reference to the chart container
  const [isInView, setIsInView] = useState(false); // Track if the chart is in view
  const selectedLocation = useSelector(selectSelectedLocation);
  const conversation = useSelector(conversations);
  const chats = useSelector(selectChats);
  const user = useSelector(selectUser);
  const [ongoingChats, setOngoingChats] = useState([]);
  const [chatInfo, setChatInfo] = useState([]);
  const [chatConversation, setChatConversation] = useState([]);
  const [chatToken, setChatToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshToken, setRefreshToken] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);

  useEffect(() => {
    if (
      selectedOption?.label !== "All Locations" &&
      chatConversation &&
      chatInfo
    ) {
      setLoading(true);
      const ongoingChats = chatInfo
        .map((chat) => {
          const matchingConversation = Object.values(chatConversation).find(
            (conv) => conv.conversation_sid === chat.conversation_sid
          );
          // Only include chats with a matching conversation that has `lastMessage`
          if (matchingConversation?.conversations?.lastMessage) {
            return {
              ...chat,
              lastMessage: matchingConversation.conversations.lastMessage, // Add `lastMessage` to each chat object
            };
          }
          return null; // Filter out if no matching conversation with `lastMessage`
        })
        .filter(Boolean) // Remove null values
        .sort(
          (a, b) =>
            new Date(b.lastMessage.dateCreated) -
            new Date(a.lastMessage.dateCreated)
        );
      setOngoingChats(ongoingChats);
      setLoading(false);
    }
  }, [chatConversation, chatInfo]);

  useEffect(() => {
    if (refreshToken) {
      getToken();
    }
  }, [refreshToken]);

  const loadConversations = async (client) => {
    setConversationLoading(true);
    try {
      const conversationsObject = {};
      for (const chat of chatInfo) {
        try {
          const result = await client.getConversationBySid(
            chat.conversation_sid
          );
          conversationsObject[chat.id] = {
            conversation_sid: chat.conversation_sid,
            conversations: result,
          };
        } catch (error) {
          if (error?.status === 404) {
            // console.log(
            //   `Conversation not found for sid: ${chat.conversation_sid}`
            // );
          } else {
            throw error;
          }
        }
      }
      setChatConversation(conversationsObject);
      setConversationLoading(false);
    } catch (error) {
      console.error(error);
      if (error?.status !== 403) {
        toast.error("Couldn't load conversations. Please Try Again.");
      }
      setConversationLoading(false);
    }
  };

  useEffect(() => {
    const setupTwilio = async (token) => {
      const authToken = token;
      let client = new Client(authToken);
      const waitForConversations = new Promise(async (resolve) => {
        client.on("stateChanged", async (state) => {
          if (state === "initialized") {
            await loadConversations(client);
            resolve();
          } else if (state === "failed") {
            setConversationLoading(false);
          }
        });
      });

      await waitForConversations;

      client.on("connectionStateChanged", (connectionState) => {
        if (connectionState === "denied") {
          setRefreshToken(true);
        }
      });

      client.on("tokenAboutToExpire", () => {
        setRefreshToken(true);
      });
    };

    if (chatToken && chatInfo.length > 0) {
      setupTwilio(chatToken);
    }
  }, [chatToken]);

  const getToken = async () => {
    try {
      if (user?.company?.is_enterprise === 0) {
        const response = await ChatApi.createToken({
          location_id: user?.location?.id,
        });
        setChatToken(response.data.token);
      } else {
        if (selectedOption?.value?.id) {
          const response = await ChatApi.createToken({
            location_id: selectedOption?.value?.id,
          });
          setChatToken(response.data.token);
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        if (chatInfo.length > 0) toast.error("Couldn't load chat.");
      }
      setConversationLoading(false);
    }
  };

  useEffect(() => {
    if (chatInfo) {
      getToken();
    }
  }, [chatInfo]);

  const loadChatInfo = async () => {
    try {
      if (user?.company?.is_enterprise === 0) {
        const response = await ChatApi.chatByLocation(user?.location?.id);
        setChatInfo(response.data.data);
      } else if (selectedOption?.value?.id) {
        const response = await ChatApi.chatByLocation(
          selectedOption?.value?.id
        );
        setChatInfo(response.data.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
    }
  };

  useEffect(() => {
    if (selectedOption?.label !== "All Locations") {
      setOngoingChats([]);
      setChatInfo([]);
      setChatConversation([]);
      setConversationLoading(true);
      loadChatInfo();
    } else {
      setOngoingChats([]);
      setChatInfo([]);
      setChatConversation([]);
      setConversationLoading(false);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedLocation) {
      if (selectedLocation?.name === "Select A Location") {
        setSelectedOption({
          value: "all-locations",
          label: "All Locations",
        });
      } else {
        setSelectedOption({
          value: selectedLocation,
          label: selectedLocation?.name,
        });
      }
    }
  }, [selectedLocation]);

  const formatDateWithTime = (date) => {
    const dateObj = dayjs(date);
    const now = dayjs();

    const yearsAgo = now.diff(dateObj, "year");
    if (yearsAgo >= 1) {
      return `${dateObj.format("DD-MM-YYYY h:mm A")} - ${yearsAgo} ${
        yearsAgo === 1 ? "year" : "years"
      } ago`;
    }

    const monthsAgo = now.diff(dateObj, "month");
    if (monthsAgo >= 1) {
      return `${dateObj.format("DD-MM-YYYY h:mm A")} - ${monthsAgo} ${
        monthsAgo === 1 ? "month" : "months"
      } ago`;
    }

    const daysAgo = now.diff(dateObj, "day");
    return `${dateObj.format("DD-MM-YYYY h:mm A")} - ${daysAgo} ${
      daysAgo === 1 ? "day" : "days"
    } ago`;
  };

  useEffect(() => {
    // Intersection Observer setup
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Set to true when the chart enters the viewport
          observer.disconnect(); // Stop observing once it's in view
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) observer.unobserve(chartRef.current);
    };
  }, []);

  useEffect(() => {
    // Render the chart when it is in view
    if (isInView) {
      const getChartOptions = () => {
        return {
          series: [52.8, 26.8, 20.4],
          colors: ["#1C64F2", "#16BDCA", "#9061F9"],
          chart: {
            height: 420,
            width: "100%",
            type: "pie",
          },
          stroke: {
            colors: ["white"],
            lineCap: "",
          },
          plotOptions: {
            pie: {
              labels: {
                show: true,
              },
              size: "100%",
              dataLabels: {
                offset: -25,
              },
            },
          },
          labels: ["Direct", "Organic search", "Referrals"],
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: "Inter, sans-serif",
            },
          },
          legend: {
            position: "bottom",
            fontFamily: "Inter, sans-serif",
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
        };
      };

      if (typeof ApexCharts !== "undefined") {
        const chart = new ApexCharts(
          document.getElementById("pie-chart"),
          getChartOptions()
        );
        chart.render();

        return () => {
          chart.destroy(); // Clean up the chart when the component is unmounted
        };
      }
    }
  }, [isInView]);

  return (
    <>
      <div className="w-full flex justify-end items-end">
        {roles[0] !== "turnkey_admin" &&
          showLocationDropdown &&
          allLocations && (
            <div className="flex my-4 md:w-1/2 lg:w-1/3 xl:w-1/4">
              <FormSelect
                type="add-job"
                label="Select Location"
                placeholder="Select Location"
                classNames="font-bold"
                options={[
                  { value: "all-locations", label: "All Locations" },
                  ...allLocations?.map((info) => ({
                    value: info,
                    label: info?.name,
                  })),
                ]}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                }}
                value={
                  selectedOption
                    ? {
                        label: selectedOption?.label || "All Locations",
                        value: selectedOption?.value || "all-locations",
                      }
                    : { value: "all-locations", label: "All Locations" }
                }
                darmo={mode}
              />
            </div>
          )}
      </div>
      <div
        className="pb-5 mx-auto z-10 bg-fixed bg-cover bg-center"
        style={{ backgroundImage: mode ? `url(${LineChart})` : "none" }}
      >
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <div className="relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
              <dt>
                <div className="absolute rounded-lg bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate font-bold text-sm text-[#333333] dark:text-gray-300">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 pb-12 pt-5 shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-lg bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-300">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 pb-12 pt-5 shadow-md dark:shadow-none shadow-gray-500 sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-300">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6 text-[#333333]">
            <div className="relative overflow-hidden rounded-lg px-4 pt-4 shadow-md dark:shadow-none border border-warmgray-200 dark:border-darkGray shadow-gray-500 sm:px-6 sm:pt-6 bg-white dark:bg-[#242526]">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-bold text-base dark:text-gray-300">
                  Ongoing Conversation
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <table className="w-full min-w-[540px]">
                  <thead>
                    <tr>
                      <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Name
                      </th>
                      <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Position
                      </th>
                      <th className="px-4 bg-primary-500 text-white dark:bg-darkGray align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                        Date / Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {conversationLoading ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-4 text-gray-900 dark:text-gray-500"
                        >
                          <i className="fad fa-circle-notch fa-spin text-lg" />
                          <div className="loader">Loading...</div>{" "}
                        </td>
                      </tr>
                    ) : ongoingChats?.length > 0 ? (
                      ongoingChats?.slice(0, 5)?.map((chat, index) => (
                        <tr key={index}>
                          <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                            <div className="flex items-center">
                              <a
                                href="#"
                                className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 truncate"
                              >
                                {chat?.seeker?.first_name +
                                  " " +
                                  chat?.seeker?.last_name}
                              </a>
                            </div>
                          </td>
                          <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                            <span className="text-[13px] font-medium text-gray-400 dark:text-gray-300">
                              {chat?.position?.title}
                            </span>
                          </td>
                          <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                            <span className="text-[13px] font-medium text-gray-400 dark:text-gray-300">
                              {formatDateWithTime(
                                chat?.lastMessage?.dateCreated
                              )}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          {selectedOption?.label !== "All Locations" &&
                          !conversationLoading ? (
                            <span className="text-gray-400">
                              No conversations
                            </span>
                          ) : (
                            <span className="text-gray-400">
                              Please choose a location to access chat
                              conversations.
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="relative overflow-hidden rounded-lg px-4 pt-4 shadow-md border border-warmgray-200 shadow-gray-500 dark:bg-[#242526] dark:border-darkGray dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
              <div className="rounded-t mb-4 border-0">
                <div className="flex justify-between mb-4 items-start">
                  <div className="font-bold text-base dark:text-gray-300">
                    Applicants
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Name
                        </th>
                        <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Position Applied
                        </th>
                        <th className="px-4 bg-primary-500 text-white dark:bg-darkGray align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                          Response Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr>
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left dark:text-gray-300">
                          kavin
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          waiter
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 dark:text-gray-300">
                          <div className="flex items-center">
                            <span className="mr-2">3 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                                <div
                                  style={{ width: "70%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left dark:text-gray-300">
                          Roman
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          cook
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          <div className="flex items-center">
                            <span className="mr-2">6 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                                <div
                                  style={{ width: "40%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left dark:text-gray-300">
                          Rimin
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          cook
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          <div className="flex items-center">
                            <span className="mr-2">5 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-pink-200">
                                <div
                                  style={{ width: "45%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left dark:text-gray-300">
                          Ashly
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          Front Desk
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 dark:text-gray-300">
                          <div className="flex items-center">
                            <span className="mr-2">4 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                <div
                                  style={{ width: "60%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
            <div className="p-6 lg:col-span-2 relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] dark:border-darkGray dark:shadow-none border border-warmgray-200 shadow-md shadow-gray-500 sm:px-6 sm:pt-6 bg-transparent">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium text-base dark:text-gray-300">
                  HeyHire Statistics - Job Title
                </div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle text-gray-400 hover:text-gray-600"
                  >
                    <i className="ri-more-fill" />
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-bold dark:text-gray-300">
                      10
                    </div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                      +2 this week
                    </span>
                  </div>
                  <span className="text-gray-500 text-sm">
                    Active Conversation
                  </span>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-bold dark:text-gray-300">
                      50
                    </div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-emerald-500/10 text-emerald-500 leading-none ml-1">
                      +10 this week
                    </span>
                  </div>
                  <span className="text-gray-500 text-sm">
                    Completed Conversation
                  </span>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-bold dark:text-gray-300">
                      4
                    </div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-emerald-500/10 text-emerald-500  leading-none ml-1">
                      +1 this week
                    </span>
                  </div>
                  <span className="text-gray-500 text-sm">Hired Status</span>
                </div>
              </div>
              <div>
                {/* <img
                  src={ChartSample}
                  alt=""
                  className="justify-center align-middle p-5 pl-52"
                /> */}
                <div id="pie-chart" ref={chartRef}></div>
              </div>
            </div>
            <div className="bg-white dark:bg-[#242526] dark:border-darkGray dark:shadow-none border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium text-base dark:text-gray-300">
                  Activities
                </div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle text-gray-400 hover:text-gray-600"
                  >
                    <i className="ri-more-fill" />
                  </button>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full min-w-[460px]">
                  <thead>
                    <tr>
                      <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Applicant
                      </th>
                      <th className="px-4 bg-primary-500 dark:bg-darkGray text-white align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Viewed Job
                      </th>
                      <th className="px-4 bg-primary-500 text-white dark:bg-darkGray align-middle border border-solid border-gray-200 dark:border-darkGray py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium ml-2 truncate"
                          >
                            Ashley
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="text-[13px] font-medium text-emerald-500">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Pending
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Kimin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="text-[13px] font-medium text-rose-500">
                          09-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="inline-block p-1 rounded bg-rose-500/10 text-rose-500 font-medium text-[12px] leading-none">
                          Withdrawn
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Ron
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="text-[13px] font-medium text-emerald-500">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Pending
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Kavin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="text-[13px] font-medium text-rose-500">
                          02-11-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="inline-block p-1 rounded bg-rose-500/10 text-rose-500 font-medium text-[12px] leading-none">
                          Withdrawn
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Samual
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="text-[13px] font-medium text-emerald-500">
                          09-01-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50 dark:border-b-gray-400">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Hired
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BusinessAnalyticDashboard = () => {
  const mode = useSelector(darkmode);
  return (
    <DashboardLayout darmo={true}>
      <div>
        <h1 className="text-xl xl:text-2xl font-bold mt-2 text-gray-900 dark:text-gray-300">
          Analytics Dashboard
        </h1>
      </div>
      <AnalyticsTable mode={mode} />
    </DashboardLayout>
  );
};

export default BusinessAnalyticDashboard;
