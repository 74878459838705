import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  addBlueIcon,
  addIcon,
  certificationsGrayIcon,
  certificationsIcon,
  certificationsWhiteIcon,
  minusIcon,
} from "../../../assets/images";
import dayjs from "dayjs";
import FormSelect from "../../atoms/FormSelect";
import FormDateInput from "../../form/FormDateInput";
import Input from "../../form/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  setEventPage,
  setNextShiftPage,
  setShiftsArray,
  shiftsArray,
} from "../../../store/authSlice";

const ShiftAddOrEdit = ({ type, editMode, editIndex }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [position, setPosition] = useState("");
  const [spots, setSpots] = useState("");
  const [time, setTime] = useState("");
  const [wage, setWage] = useState("");
  const [isInBoundShift, setInBoundShift] = useState(false);
  const newShiftsArray = useSelector(shiftsArray);
  const [startDate, setStartDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [minDate, setMinDate] = useState(new Date());
  const mode = useSelector(darkmode);
  const certificateData = [
    {
      name: "Food Handlers",
      id: 1,
    },
    {
      name: "Food Safety",
      id: 2,
    },
    {
      name: "TABC",
      id: 3,
    },
  ];
  const [selectedCertifications, setSelectedCertifications] = useState([]);

  const handleAddCertification = (item) => {
    setSelectedCertifications((prevSelected) => [...prevSelected, item]);
  };

  const handleRemoveCertification = (item) => {
    setSelectedCertifications((prevSelected) =>
      prevSelected.filter((selectedItem) => selectedItem !== item)
    );
  };

  const positionList = [
    { value: "Bartender", label: "Bartender" },
    { value: "Server", label: "Server" },
    { value: "Barista", label: "Barista" },
    { value: "Door Staff", label: "Door Staff" },
    { value: "DishWasher", label: "DishWasher" },
    { value: "Busser", label: "Busser" },
  ];

  const timingList = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Evening", label: "Evening" },
    { value: "Night", label: "Night" },
  ];

  const NumberOfSpotsList = Array.from({ length: 10 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  useEffect(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2);
    setMinDate(tomorrow);
  }, []);

  const validateFields = () => {
    const newErrors = {};
    if (!position) {
      newErrors.position = "Position is required";
    }
    if (!spots) {
      newErrors.spots = "Spots are required";
    }
    if (!wage) {
      newErrors.wage = "Wage is required";
    }
    if (!time) {
      newErrors.time = "Time is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const addShift = () => {
    if (validateFields()) {
      const newShift = {
        position,
        spots,
        wage,
        time,
        startDate,
        selectedCertifications,
        isInBoundShift,
      };

      dispatch(setShiftsArray([...newShiftsArray, newShift]));
      dispatch(setNextShiftPage(true));
    }
  };

  const editShift = () => {
    if (validateFields()) {
      const updatedShift = {
        position,
        spots,
        wage,
        time,
        startDate,
        selectedCertifications,
        isInBoundShift,
      };

      dispatch(
        setShiftsArray(
          newShiftsArray.map((shift, i) =>
            i === editIndex ? updatedShift : shift
          )
        )
      );
      dispatch(setNextShiftPage(true));
    }
  };

  useEffect(() => {
    if (editMode) {
      setPosition(editMode?.position);
      setWage(editMode?.wage);
      setInBoundShift(editMode?.isInBoundShift);
      setSpots(editMode?.spots);
      setTime(editMode?.time);
      setSelectedCertifications(editMode?.selectedCertifications);
    }
  }, [editMode]);

  return (
    <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
        <div className="mb-4 md:mb-0">
          <FormSelect
            type={"add-job"}
            label="Shift Position"
            name="shift-position"
            onChange={(value) => {
              setPosition(value.value);
            }}
            darmo={mode}
            options={positionList}
            defaultValue={position}
            placeholder={
              positionList[
                positionList.findIndex((obj) => obj.value === position)
              ]?.label
            }
          />
          {errors?.position && (
            <p className="mt-1 text-red-400 text-xs font-bold">
              {errors.position}
            </p>
          )}
        </div>

        <div className="mb-4 md:mb-0">
          <FormSelect
            type={"add-job"}
            label="Number of spots?"
            name="spots"
            onChange={(value) => {
              setSpots(value.value);
            }}
            darmo={mode}
            options={NumberOfSpotsList}
            defaultValue={spots}
            placeholder={
              NumberOfSpotsList[
                NumberOfSpotsList.findIndex((obj) => obj.value === spots)
              ]?.label
            }
          />
          {errors?.spots && (
            <p className="mt-1 text-red-400 text-xs font-bold">
              {errors.spots}
            </p>
          )}
        </div>
      </div>

      <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
        <div className="mb-4 md:mb-0">
          <FormDateInput
            dateType={"add-job"}
            name="shift_date"
            label="Date"
            type="date"
            min={minDate.toISOString().split("T")[0]}
            value={startDate}
            placeholder={t("pre-defined-jobs.start-date")}
            onChange={(e) => setStartDate(e.target.value)}
            darmo={mode}
          />
        </div>

        <div className="mb-4 md:mb-0">
          <FormSelect
            type={"add-job"}
            label="Shift Time"
            name="shift-time"
            onChange={(value) => {
              setTime(value.value);
            }}
            darmo={mode}
            options={timingList}
            defaultValue={time}
            placeholder={
              timingList[timingList.findIndex((obj) => obj.value === time)]
                ?.label
            }
          />
          {errors?.time && (
            <p className="mt-1 text-red-400 text-xs font-bold">{errors.time}</p>
          )}
        </div>
      </div>

      <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
        <div>
          <Input
            name="wage"
            label={t("pre-defined-jobs.wage")}
            type="text"
            value={wage}
            slug={"$"}
            placeholder="8.50/HR + Tips & Benefts"
            onChange={(e) => setWage(e.target.value)}
            darmo={mode}
          />
          {errors?.wage && (
            <p className="text-red-400 text-xs font-bold mt-1">{errors.wage}</p>
          )}
        </div>

        <div className="flex items-center">
          <input
            className="mr-2 cursor-pointer my-auto rounded"
            type="checkbox"
            name="isInBoundShift"
            checked={isInBoundShift}
            onChange={(e) => setInBoundShift(e.target.checked)}
          />
          <span className="text-gray-900 dark:text-gray-300">
            Allow In-abound Shifters to apply?
          </span>
        </div>
      </div>

      <div className="2xl:w-3/4">
        <div className="mb-2 md:mb-0">
          <label className="font-bold text-gray-900 dark:text-gray-100">
            Required Certificates
          </label>
          <div className="mt-3">
            <div className="w-full flex flex-wrap gap-4">
              {certificateData?.map((item) => {
                const isCertificationSelected = selectedCertifications?.some(
                  (selectedItem) => selectedItem === item?.name
                );
                const bgColorClass = isCertificationSelected
                  ? "bg-primary-500 dark:bg-[#3E4042]"
                  : "bg-white dark:bg-[#242526]";
                const iconSrc = isCertificationSelected
                  ? certificationsWhiteIcon
                  : mode
                  ? certificationsIcon
                  : certificationsGrayIcon;
                const textColorClass = isCertificationSelected
                  ? "text-white"
                  : "text-gray-900 dark:text-gray-100";
                const clickHandler = isCertificationSelected
                  ? () => handleRemoveCertification(item?.name)
                  : () => handleAddCertification(item?.name);

                return (
                  <div
                    key={item?.id}
                    className={`relative border-2 border-primary-500 dark:border-none dark:hover:opacity-80 p-3 rounded-xl w-36 h-36 ${bgColorClass} transition-all duration-200`}
                  >
                    <div className="flex flex-col h-full">
                      <div className="flex justify-center mb-2">
                        <img
                          src={iconSrc}
                          alt="certificate"
                          className="w-10 h-10"
                        />
                      </div>

                      <div className="flex-1 flex items-center justify-center px-1">
                        <p
                          className={`${textColorClass} font-bold text-center text-sm`}
                        >
                          {item?.name}
                        </p>
                      </div>

                      <div className="flex justify-center">
                        <button
                          onClick={clickHandler}
                          className="w-6 h-6 flex items-center justify-center focus:outline-none"
                        >
                          <img
                            src={
                              isCertificationSelected
                                ? minusIcon
                                : mode
                                ? addBlueIcon
                                : addIcon
                            }
                            alt={isCertificationSelected ? "remove" : "add"}
                            className="w-5 h-5"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full md:w-1/5 mt-12 justify-center md:justify-start">
        {type === "event" ? (
          <div className="flex gap-4">
            <button
              className="w-36 cursor-pointer flex justify-center items-center rounded-full bg-orange-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-orange-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                newShiftsArray?.length > 0
                  ? dispatch(setNextShiftPage(true))
                  : dispatch(setEventPage(false));
              }}
            >
              Previous
            </button>

            <button
              className="w-36 cursor-pointer flex justify-center items-center rounded-full bg-orange-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-orange-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                editMode ? editShift() : addShift();
              }}
            >
              <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
              {editMode ? "Edit Shift" : "Add"}
            </button>
          </div>
        ) : (
          <>
            <button
              className="cursor-pointer flex justify-center items-center rounded-full bg-orange-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-orange-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addShift();
              }}
              // disabled={loading}
            >
              <img src={addIcon} alt="logo" className="mr-2 my-auto w-4 h-4" />
              {editMode ? t("pre-defined-jobs.update-job") : "Open Shift"}
              {/* {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              )} */}
            </button>

            {/* <div className="text-center">
              {!editMode && ( 
              <button
                // onClick={saveDraft}
                className={`px-6 border mr-1 flex items-center justify-center bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
              >
                Save as draft
              </button> 
              )}
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default ShiftAddOrEdit;
