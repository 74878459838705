import React, { useEffect, useRef, useState } from "react";
import { Input, Form, Select } from "antd";
// import { default as ReactSelect } from "react-select";
// import { components } from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ChatMessage from "./ChatMessage";
import CannedMessage from "../api/CannedMessage";
import ChatAlerts from "../api/ChatAlerts";
import ChatApi from "../api/Chat";
import { toast } from "react-toastify";
import "./Chat.scss";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { DateFormat, encodeIds } from "../utils";
import { defaultUserImage, BellActive, BellInActive } from "../assets/images";
import { selectUser, selectSelectedLocation } from "../store/authSlice";

const Chat = ({
  conversation,
  chat,
  alertSubcriprionList,
  reloadSubscribedUsers,
  activeParticipants,
}) => {
  const [form] = Form.useForm();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [precannedMessages, setPrecannedMessages] = useState([]);
  const [selectedPcm, setSelectedPcm] = useState("");
  const [pcmLoading, setPcmLoading] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);
  const [isSubscibed, setIsSubscribed] = useState(false);
  const [selectedConversation, setSelectedConversation] =
    useState(conversation);
  const [boundConversations, setBoundConversations] = useState(new Set());
  const navigate = useNavigate();
  const { t } = useTranslation();

  const chatDivRef = useRef(null);

  useEffect(() => {
    alertSubcriprionList.find((item) => {
      if (item.user_id === user.id) {
        setIsSubscribed(true);
      }
    });
    loadPrecannedMessages();
  }, [alertSubcriprionList]);

  useEffect(() => {
    setSelectedConversation(conversation);
  }, [conversation]);

  useEffect(() => {
    if (selectedConversation) {
      loadMessages(selectedConversation);

      if (!boundConversations.has(selectedConversation)) {
        let newConversation = selectedConversation;
        newConversation.on("messageAdded", (m) =>
          onMessageAdded(m, newConversation)
        );
        setBoundConversations((b) => new Set([...b, newConversation]));
      }
    }
  }, [selectedConversation]);

  const loadMessages = (thisConversation) => {
    if (thisConversation === selectedConversation) {
      setMessagesLoading(true);
      thisConversation
        .getMessages()
        .then((messagePaginator) => {
          if (thisConversation === selectedConversation) {
            setMessages(messagePaginator.items);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 403) {
            toast.error("Couldn't fetch messages.");
          }
        })
        .then(() => {
          setMessagesLoading(false);
        });
    }
  };

  const deleteChatAlerts = () => {
    const chatItem = alertSubcriprionList.filter(
      (value) => value.user_id === user.id
    );
    ChatAlerts.deleteChatAlert(chatItem[0].id)
      .then((response) => {
        setIsSubscribed(false);
        reloadSubscribedUsers(chat.id);
        toast.success(`Unsubscrubed from Chat Alerts`);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong. Please try again later.");
        }
      });
  };

  const addChatAlerts = () => {
    const body = {
      chat_id: chat.id,
    };
    ChatAlerts.createChatAlert(body)
      .then((response) => {
        setIsSubscribed(true);
        reloadSubscribedUsers(chat.id);
        toast.success(`Subscribed for Chat Alerts`);
      })
      .catch((error) => {
        toast.success("Something went wrong. Please try again later.");
      });
  };

  const onMessageAdded = (m, targetConversation) => {
    if (targetConversation === selectedConversation) {
      setMessages((oldMessages) => [...oldMessages, m]);
      scrollToBottom();
    }
  };

  const scrollToBottom = (delay = 0) => {
    setTimeout(() => {
      chatDivRef.current.scrollTop = chatDivRef?.current?.scrollHeight;
    }, delay);
  };

  const sendMessage = (values) => {
    // updateActivityTimestamp(chat.id);
    if (messages?.length == 0) {
      const welcome_message = `HeyHire Intro Message: ${
        user?.location?.name
      } ${t("applicant.welcome-message-1")}\n${t("common.message")}: ${
        values.message
      }\n${t("applicant.welcome-message-2")}`;
      conversation.sendMessage(welcome_message);
    } else {
      conversation.sendMessage(values.message);
    }
    setMessage("");
    form.resetFields();
  };

  const loadPrecannedMessages = () => {
    setPcmLoading(true);
    CannedMessage.getAll()
      .then((response) => setPrecannedMessages(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Couldn't load pre-canned messages.");
        }
      })
      .then(() => setPcmLoading(false));
  };

  const navigateProfile = () => {
    navigate(
      `/business/applicant/${encodeIds(chat?.position?.id)}/${encodeIds(
        chat?.seeker.id
      )}`
    );
  };

  const navigateJob = () => {
    navigate(`/business/view-job/${encodeIds(chat?.position?.id)}`);
  };

  const updateActivityTimestamp = async (id) => {
    ChatApi.updateLastSeen(id);
  };
  /*
  const handleChange = (selected) => {
    console.log('handleChange -> selected', selected);
    if (selected[0]?.isAdded) {
      // deleteChatAlerts(selected[0]);
    } else {
      joinChatCall(selected[0]);
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.data?.isAdded}
            onChange={() => null}
          />{" "}
          <label>{`${props?.data?.first_name} ${props?.data?.last_name}`}</label>
        </components.Option>
      </div>
    );
  };
*/
  if (messagesLoading) {
    return (
      <div
        className={
          "w-full h-full flex justify-center items-center border-4 border-gray-300 rounded-md"
        }
      >
        <i className="fad fa-circle-notch fa-spin ml-2 text-3xl text-primary-500" />
      </div>
    );
  } else if (conversation) {
    return (
      <div className="w-full h-full flex flex-col border-4 border-gray-300 rounded-md">
        <div className="border-b border-gray-200 flex p-2 px-4">
          <img
            src={chat?.seeker?.photo?.thumb_url || defaultUserImage}
            className={"w-8 h-8 rounded-full self-center"}
            alt="Profile"
          />
          <div className="ml-4">
            <span
              className="font-bold text-primary-500 block cursor-pointer hover:underline"
              onClick={navigateProfile}
            >
              {chat ? chat.seeker.first_name + " " + chat.seeker.last_name : ""}
            </span>
            <span className="flex ">
              <span className="text-gray-400 text-xs">
                Application Pending for{" "}
                <span
                  className="hover:underline cursor-pointer"
                  onClick={navigateJob}
                >
                  {chat && chat.position ? chat.position.title : ""},
                </span>{" "}
                at {selectedLocation?.name},{" "}
              </span>
              <span className="ml-2 text-gray-400 text-xs">
                {" "}
                Applied On: {dayjs(chat?.created_at).format(DateFormat)}
              </span>
            </span>
          </div>
          <div className="flex flex-1 justify-end mr-2">
            {isSubscibed ? (
              <button onClick={() => deleteChatAlerts()}>
                <img
                  src={BellActive}
                  alt="bell-logo"
                  className="h-6 w-5 ml-4 fill-primary-500"
                />
              </button>
            ) : (
              <button onClick={() => addChatAlerts()}>
                <img
                  src={BellInActive}
                  alt="bell-logo"
                  className="h-6 w-5 ml-4 fill-primary-500"
                />
              </button>
            )}
          </div>
        </div>

        <div
          id={"aaa"}
          ref={chatDivRef}
          className="flex-grow border-b border-gray-200 overflow-y-auto"
        >
          {messages.map((message) => {
            return (
              <ChatMessage
                key={message.state.sid}
                message={message}
                senderList={activeParticipants}
              />
            );
          })}
        </div>

        <div className="p-2 flex flex-col md:flex-row">
          <Form
            onFinish={sendMessage}
            layout="vertical"
            className="w-full md:mr-4"
            form={form}
          >
            <Form.Item
              name="message"
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="flex flex-row items-center">
                <Input.TextArea
                  className="bg-gray-200 hover:bg-gray-200 active:bg-gray-200 h-[40px]"
                  placeholder="Send New Message"
                  bordered={true}
                  autoSize={true}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  size="middle"
                />
                <button
                  type="submit"
                  className="px-2 rounded-sm bg-primary-500 text-white h-[30px] ml-1"
                >
                  <i className="far fa-angle-right" />
                </button>
              </div>
            </Form.Item>
          </Form>
          <div className="w-full md:w-[210px] mt-4 md:mt-0 h-[40px]">
            <Select
              placeholder={"Pre-Canned Message"}
              onChange={(e) => {
                form.setFieldsValue({ message: e });
                setMessage(e);
              }}
              className="canned-message-select"
            >
              {precannedMessages.map((_option, _i) => (
                <Select.Option key={_i} value={_option.message}>
                  {_option.shortcut}
                </Select.Option>
              ))}
            </Select>
          </div>
          {/*<div>
            <ReactSelect
              className="w-full md:w-[210px] text-md mt-4 md:mt-0 h-[40px]"
              options={participants}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option
              }}
              onChange={(e) => handleChange(e)}
              allowSelectAll={false}
              placeholder={"Add to Chat"}
              value={"Add to Chat"}
            />
            </div>*/}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          "w-full h-full flex justify-center items-center border-4 border-gray-300 rounded-md"
        }
      >
        Select a Chat To Get Started.
      </div>
    );
  }
};

export default Chat;
