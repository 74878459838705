import React, { Suspense, lazy, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectRoles } from "../../../store/authSlice";

// Lazy load the ChatList component
const ChatList = lazy(() =>
  import("../../../components/organisms/Chat/ChatList")
);

const LazyLoadedChat = () => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const role = roles.length > 0 ? roles[0] : "";
  const location = window.location.pathname;

  const excludedPaths = [
    "/login",
    "/subscribe",
    "/register",
    "/forgot-password",
    "/password-reset",
    "/file-upload",
    "/heyhire",
    "/401",
    "/subscription",
    "/plans",
  ];

  const isPathExcluded = (path) => {
    return (
      excludedPaths.includes(path) ||
      path.startsWith("/password-reset/") ||
      path.startsWith("/easy-login") ||
      path.startsWith("/easy-registration") ||
      path.startsWith("/easy-confirmation") ||
      path.startsWith("/heyhire/")
    );
  };

  useEffect(() => {
    // Only load ChatList if all conditions are met
    const shouldRenderChat =
      !isPathExcluded(location) && role !== "super-user" && user?.id; // Ensure user is authenticated

    // Add a small delay to prevent immediate loading
    const timer = setTimeout(() => {
      setShouldLoad(shouldRenderChat);
    }, 100);

    return () => clearTimeout(timer);
  }, [location, role, user]);

  if (!shouldLoad) return null;

  return (
    <Suspense fallback={<div />}>
      <ChatList />
    </Suspense>
  );
};

export default LazyLoadedChat;
