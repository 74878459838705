import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesList from "./routes";
import "./App.css";
import Login from "./pages/auth/Login";
import RouteGuard from "./components/RouteGuard";
import Dashboard from "./pages/Dashboard";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import Register from "./pages/auth/Register";
import RegionalManagerDashboard from "./pages/RegionalManagerDashboard";
import BusinessOwnerDashboard from "./pages/BusinessOwnerDashboard";
import {
  selectRoles,
  selectUser,
  selectSelectedLocation,
} from "./store/authSlice";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Settings from "./pages/admin/Settings/Settings";
import { setNavigateFunction } from "./api/api";
import JobPosition from "./api/JobPosition";
import TagManager from "react-gtm-module";
import LazyLoadedChat from "./components/organisms/Chat/LazyLoadedChat";

function App() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const selectedLocation = useSelector(selectSelectedLocation);
  const role = roles.length > 0 ? roles[0] : "";

  const DashboardCont = () => {
    const isEnterprise = user?.company?.is_enterprise === 1;
    if (role === "super-user") {
      return <Dashboard />;
    } else if (role === "turnkey_admin") {
      return <Settings />;
    } else if (role === "regional_manager" && !selectedLocation?.id) {
      return <RegionalManagerDashboard />;
    } else if (role === "owner" && isEnterprise && !selectedLocation?.id) {
      return <BusinessOwnerDashboard />;
    } else {
      return <BusinessDashboard />;
    }
  };

  useEffect(() => {
    setNavigateFunction(navigate);
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_KEY });
  }, [navigate]);

  const checkJobCreated = async () => {
    const info = await JobPosition.checkJobCreated();
    if (!info?.data) {
      navigate("/add-sub-user");
    }
  };

  useEffect(() => {
    if (user) {
      checkJobCreated();
    }
  }, [user]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <RouteGuard guest={true}>
              <Login />
            </RouteGuard>
          }
        />
        <Route
          path={"/plans"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/register"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/plans/:plan_type"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/forgot-password"}
          element={
            <RouteGuard guest={true}>
              <ForgotPassword />
            </RouteGuard>
          }
        />
        <Route
          path={"/password-reset/:token"}
          element={
            <RouteGuard guest={true}>
              <ResetPassword />
            </RouteGuard>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RouteGuard auth={true}>
              <DashboardCont />
            </RouteGuard>
          }
        />
        <Route
          path="/rm/dashboard"
          element={
            <RouteGuard auth={true}>
              <RegionalManagerDashboard />
            </RouteGuard>
          }
        />
        <Route
          path="/bo/dashboard"
          element={
            <RouteGuard auth={true}>
              <BusinessOwnerDashboard />
            </RouteGuard>
          }
        />

        {RoutesList.map((ro, i) => (
          <Route
            exact
            key={i}
            path={ro.path}
            element={
              ro.auth ? (
                <RouteGuard auth={ro.auth}>{ro.element}</RouteGuard>
              ) : (
                <>{ro.element}</>
              )
            }
          />
        ))}

        <Route
          path="*"
          element={
            <RouteGuard auth={true}>
              <Navigate to="/dashboard" />
            </RouteGuard>
          }
        />
      </Routes>
      <LazyLoadedChat />
      <ToastContainer
        theme={
          localStorage.getItem("theme") === "dark"
            ? "dark"
            : localStorage.getItem("theme") === "device" &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        }
      />
    </>
  );
}

export default App;
